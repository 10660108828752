<template>
    <div class="projectarchives flex-col item-b" v-loading="showLoad" element-loading-text="拼命加载中">
        <!-- 查询控制 -->
        <div class="header">
            <div class="search-box">
                <el-input class="custom-input" v-model="searchText" placeholder="请输入项目部名称"></el-input>
            </div>
            <el-button class="btn item-primary" type="primary " @click="searchBtn">查询</el-button>
            <el-button class="btn item-warning" type="warning" @click="addBtn">新增</el-button>
            <el-button class="btn item-info" type="info" @click="resetBtn">重置</el-button>
        </div>

        <!-- 表格内容 -->
        <div class="content">
            <el-table :data="dataList" stripe :header-cell-style="tableHeaderColor" :cell-style="rowClass">
                <el-table-column align="center" width="60" label="序号"  type="index"></el-table-column>
                <el-table-column align="center" label="项目部名称" prop="deptName"></el-table-column>
                <el-table-column align="center" label="负责人" prop="staffName"></el-table-column>
                <el-table-column align="center" label="联系电话" prop="phone"></el-table-column>
                <el-table-column align="center" label="项目部人数" prop="sum"></el-table-column>
                <el-table-column align="center" label="操作" min-width="150px">
                    <template slot-scope="scope">
                        <div class="flex btnbox">
                            <el-button plain type="primary" size="small" @click="editBtn(scope)">编辑</el-button>
                            <el-button plain type="danger" size="small" @click="removeBtn(scope)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="c-center page-control">
                <el-pagination
                    :total="total"
                    background
                    layout="total,prev, pager, next, jumper"
                    @current-change="pageChange"
                    :current-page.sync="curIndex"
                ></el-pagination>
            </div>
        </div>

        <!-- 分页器 -->
        

        <!-- 新增项目部 -->
        <el-drawer
            custom-class="drawer-class"
            :visible.sync="show"
            :before-close='cancelAdd'
            :show-close="false"
            :withHeader="false"
            :wrapperClosable="false"
        >
            <div class="drawer-box flex-col">
                <div class="title">新增项目部</div>
                <div class="table auto">
                    <el-form
                        :model="addPJData"
                        size="small"
                        :rules="addPJDataRule"
                        label-position="left"
                        label-width="auto"
                        ref="addForm"
                        :hide-required-asterisk="true"
                    >
                        <el-form-item label="项目部名称" prop="projectName">
                            <el-input placeholder="请输入项目部名称" v-model="addPJData.projectName"></el-input>
                        </el-form-item>

                        <el-form-item label="负责人" prop="staff">
                            <el-select v-model="addPJData.staff" placeholder="请选择负责人" @change="changeStaff">
                                <el-option
                                    v-for="item in staffList"
                                    :label="item.staffName"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item label="联系电话">
                            <el-input placeholder="联系电话" v-model="phone" disabled></el-input>
                        </el-form-item>
                        
                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button type="primary submit-primary" @click="saveAdd">保存</el-button>
                    <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
                </div>
            </div>
        </el-drawer>

        <!-- 编辑项目部 -->
        <el-drawer
            custom-class="drawer-class"
            :visible.sync="showEdit"
            :before-close='cancelEdit'
            :show-close="false"
            :withHeader="false"
            :wrapperClosable="false"
        >
            <div class="drawer-box flex-col">
                <div class="title">编辑项目部</div>
                <div class="table auto">
                    <el-form
                        :model="editPJData"
                        size="small"
                        :rules="addPJDataRule"
                        label-position="left"
                        label-width="auto"
                        ref="editForm"
                        :hide-required-asterisk="true"
                    >
                        <el-form-item label="项目部名称" prop="projectName">
                            <el-input placeholder="请输入项目部名称" v-model="editPJData.projectName"></el-input>
                        </el-form-item>
                        <el-form-item label="负责人" prop="staff">
                            <el-select v-model="editPJData.staff" placeholder="请选择负责人" @change="changeStaff">
                                <el-option
                                    v-for="item in staffList"
                                    :label="item.staffName"
                                    :value="item.id"
                                    :key="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input placeholder="联系电话" v-model="phone" disabled></el-input>
                        </el-form-item>

                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button type="primary submit-primary" @click="saveUpdate">保存</el-button>
                    <el-button type="info submit-info" @click="cancelEdit">取消</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import axios from "../request";
import {tableHeaderColor,rowClass} from '@/utils/table'
export default {
    name: "ProjectArchives",
    data() {
        return {
            show: false,
            showEdit: false,
            showLoad: true,
            curIndex: 1,
            searchText: "",
            total: 0,
            staffList: [],  //联系人列表
            dataList: [],
            phone: "",
            // 增加项目部的数据字段
            addPJData: {
                projectName: "",
                staff: "",
            },
            // 编辑项目部的数据字段
            editPJData: {
                projectName: "",
                staff: "",
                id: ""
            },
            // 校验规则
            addPJDataRule: {
                projectName: [
                    { required: true, message: "请输入项目部名称" },
                    { max: 20, message: "最多20个字符" },
                ],
                staff: [{ required: false, message: "请选择负责人" }],
            },
        };
    },
    methods: {
        tableHeaderColor({ row, rowIndex }) {
        return tableHeaderColor()
        },
        rowClass({ row, rowIndex }) {
            return rowClass()
        },
        cancelAdd() {
            this.$refs.addForm.resetFields();
            this.addPJData=this.$options.data().addPJData
            this.phone=''
            this.show = false;
        },
        cancelEdit() {
             this.$refs.editForm.resetFields();
             this.phone=''
            this.showEdit = false;
        },
        // 提交新增
        saveAdd() {
            this.$refs.addForm.validate(async valid => {
                if (valid) {
                    const params = {
                        deptName: this.addPJData.projectName,
                        staffId: this.addPJData.staff,
                    };
                    let data = await axios.post("/projectDept/add", params)
                    if (data === null) {
                        this.cancelAdd();
                        this.resetBtn();
                        this.$message({
                            message: "添加成功！",
                            type: "success",
                        });
                    }
                }
            });
        },
        // 提交编辑
        saveUpdate() {
             this.showLoad = true;
            this.$refs.editForm.validate(async valid => {
                if (valid) {
                    const params = {
                        deptName: this.editPJData.projectName,
                        staffId: this.editPJData.staff,
                        id: this.editPJData.id
                    };
                   
                    let data = await axios.post("/projectDept/update", params);
                    
                    if (data === null) {
                        this.cancelEdit();
                        this.resetBtn();
                        this.$message({
                            message: "编辑成功！",
                            type: "success",
                        });
                        this.showLoad = false;
                    }
                }
            });
        },
        async pageChange(index = 1) {
            
            let data = await axios.get(`/projectDept/page/${index}/10`, { params: { deptName: this.searchText } });
           
            if (data) {
                this.total = data.total;
                this.dataList = data.list;
            }
             this.showLoad = false;
        },
        searchBtn() {
            if (this.searchText === "") return;
            this.pageChange();
        },
        resetBtn() {
            this.searchText = "";
            this.curIndex = 1;
            this.pageChange();
        },
        removeBtn({row}) {
            this.$confirm("此操作将永久删除此, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
                let data = await axios.post(`/projectDept/deleteById/${row.id}`)
                if(data === null){
                    this.resetBtn()
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                }
            })
            .catch(() => {});
        },
        // 编辑按钮
        editBtn({row}) {
            console.log(row);
            this.getData();
            this.showEdit = true;
            this.editPJData.projectName = row.deptName;
            this.editPJData.staff = row.staffId;
            this.editPJData.id = row.id;
            this.phone =row.phone
           // this.phone = this.staffList[row.staffId - 1].phone
             console.log(this.staffList);
        },
        addBtn() {
            this.getData();
            this.show = true;
        },
        async getData() {
            // 获取联系人列表
            if (this.staffList.length < 1){
                let data = await axios.get("/deptStaff/findAll");
                if (data) {
                    this.staffList = data;
                }
            }
        },
        changeStaff(e){
            let phones=this.staffList.find(res=>{
                if(e==res.id){
                    return res.phone
                }
            })
            this.phone=phones.phone
            //this.phone = this.staffList[e-1].phone;
        }
    },
    created() {
        this.getData()
        this.pageChange();
    },
    
};
</script>

<style scoped lang="scss">
.projectarchives {
    flex: auto;
    height: 100%;
    box-sizing: border-box;
    & ::v-deep .el-drawer__body {
        overflow: auto;
    }
}
.header {
    flex: none;
    display: flex;
    padding: 20px;
    .search-box {
        width: 12rem;
        margin-right: 10px;
    }
    .btn{
       font-weight: 500;
        border: none;
    }
}
.content {
    overflow: auto;
    flex: auto;
    width: calc(100% - 8px);
}
.page-control {
   position: relative;
    top:5%;
}
.drawer-box {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    .title {
        padding: 20px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        border-bottom: solid 1px #dadada;
    }
    .table {
        padding: 20px;
        overflow: auto;
    }
    .btn {
        padding: 30px 0;
    }
}
.drawer-class ::v-deep .el-drawer__body {
    overflow: auto;
}
.drawer-box ::v-deep .el-select {
    width: 100%;
}
.btnbox {
    margin: 0 auto;
    width: 132px;
}
.el-button--danger.is-plain{
    background: white;
}
.el-button--danger.is-plain:focus{
    background: white;
    color: #F56C6C;
}
.el-button--danger.is-plain:hover{
    background: #F56C6C;
    border-color: #F56C6C;
    color: #FFF;
}
.el-button--danger.is-plain:active{
    background: white;
    color: #F56C6C;
}
.el-button--primary.is-plain{
    background: white;
}
.el-button--primary.is-plain:focus{
        color: #409EFF;
    background: white;
}
.el-button--primary.is-plain:hover{
        color: #fff;
    background: #409EFF;
    border-color: #409EFF;
}
.el-button--primary.is-plain:active{
        color: #409EFF;
    background: white;
    //border-color: #b3d8ff;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color:#3B7FFF ;
}
@media screen and(max-height: 1080px ){
 ::v-deep .page-control{
        text-align: center;
        margin: 4% 0px;
    }

}
@media screen and(max-height: 970px ){
 ::v-deep .page-control{
        text-align: center;
        margin: 1% 0px;
    }
}
</style>
